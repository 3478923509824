import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import styled from 'styled-components';

// Previews
import { withPreview } from 'gatsby-source-prismic';

import MailingList from '../components/Contact/mailingList';

const ContactContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  & a:hover {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const AboutText = styled.div`
  font-family: 'EVERYONERegular';
  font-size: 36px;
  line-height: 45px;

  text-align: center;
  text-transform: uppercase;

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  & a {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

const ContactText = styled.div`
  color: rgb(250, 60, 68);

  & a:hover {
    color: rgba(1, 59, 210, 1);
  }

  p {
    // font-size: 16px;
    // line-height: 23px;
    margin: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 1em;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 100px 0;

  height: calc(100vh - 142px - 80px);

  @media (max-width: 767px) {
    padding: 50px 0;
    height: 100%;
  }
`;

const Links = styled.div`
  width: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  // font-size: 16px;
  // line-height: 23px;

  margin-bottom: 50px;

  & a {
    padding-right: 40px;

    &:hover {
      color: #fa3c44;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 1em;
  }
`;

// const BodyLayout = {
//   flex: '1',
//   flexDirection: 'column',
//   minHeight: 'calc(100vh - 80px)',
//   display: 'flex',
// };

const RowLayout = {
  flex: '1',
};

const Contact = ({ data }) => {
  return (
    <React.Fragment>
      <Helmet title={'Contact – Everyone Agency'} />

      <Col col={12}>
        <Row justifyContent="center" style={RowLayout}>
          <Col col={12} md={10} lg={9} xl={7}>
            <AboutContainer>
              <AboutText
                dangerouslySetInnerHTML={{
                  __html: data.prismicAbout.data.about_text.html,
                }}
              />
            </AboutContainer>
          </Col>
        </Row>

        <Row>
          <Col col={12}>
            <Row justifyContent="space-between">
              <Col col={12} md={6} order={12} mdOrder={1}>
                <Links>
                  <a
                    href={data.prismicHomepage.data.instagram.url}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>

                  <a
                    href={data.prismicHomepage.data.vimeo.url}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    Vimeo
                  </a>

                  <a
                    href={data.prismicAbout.data.portfolio_link.url}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    {data.prismicAbout.data.portfolio_text}
                  </a>

                  <MailingList
                    text={data.prismicAbout.data.mailing_list_text}
                  />
                </Links>
              </Col>

              <Col col={12} md={6} order={1} mdOrder={12}>
                <ContactContainer>
                  <ContactText
                    dangerouslySetInnerHTML={{
                      __html: data.prismicAbout.data.contact_text.html,
                    }}
                  />
                </ContactContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};
export default withPreview(Contact);

export const pageQuery = graphql`
  query ContactQuery {
    prismicAbout {
      data {
        about_title {
          html
        }
        about_text {
          html
        }
        portfolio_text
        portfolio_link {
          url
        }
        animation_text
        animation_link {
          url
        }
        mailing_list_text
        contact_text {
          html
        }
        secondary_contact_text {
          html
        }
      }
    }
    prismicHomepage {
      data {
        instagram {
          url
        }
        vimeo {
          url
        }
        facebook {
          url
        }
      }
    }
  }
`;
